import React from 'react';
import '../src/css/OrdersTotals.css';
import OrdersTotals from '../src/components/OrdersTotals';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Consulta Número de Órdenes</h1>
      </header>
      <main>
        {/* Incluimos el componente OrderTotals */}
        <OrdersTotals />
      </main>
    </div>
  );
}

export default App;
