import React, { useState } from 'react';
import axios from 'axios';
import { DatePicker, Button, Spin, Alert } from 'antd'; 
import 'antd/dist/reset.css';
import '../css/OrdersTotals.css';

const { RangePicker } = DatePicker; // Selector de rango de fechas

const OrdersTotals = () => {
  const [totales, setTotales] = useState(null); // Estado para almacenar totales
  const [loading, setLoading] = useState(false); // Estado para el indicador de carga
  const [error, setError] = useState(null); // Estado para manejar errores
  const [dates, setDates] = useState([null, null]); // Estado para el rango de fechas

  const estadosPermitidos = [
    'enviado',
    'faltante',
    'facturado',
    'alistamiento',
    'pedidolisto',
    'plencontrados',
    'enviadoasistidobg',
    'busqueda',
    'epayco-completed',
    'completed',
    'processing',
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const [startDate, endDate] = dates;

    if (!startDate || !endDate) {
      setError('Por favor, selecciona un rango de fechas válido.');
      setLoading(false);
      return;
    }

    try {
      // 1. Obtener todas las órdenes en el rango de fechas
      const ordersResponse = await axios.get(
        'https://maemioficial.com/wp-json/wc/v3/orders',
        {
          auth: {
            username: process.env.REACT_APP_WC_KEY,
            password: process.env.REACT_APP_WC_SECRET,
          },
          params: {
            after: startDate.startOf('day').toISOString(), // Convertimos a ISO
            before: endDate.endOf('day').toISOString(),
            per_page: 100,
          },
        }
      );

      const orders = ordersResponse.data;

      // 2. Obtener los totales de pedidos por estado
      const totalsResponse = await axios.get(
        'https://maemioficial.com/wp-json/wc/v3/reports/orders/totals',
        {
          auth: {
            username: process.env.REACT_APP_WC_KEY,
            password: process.env.REACT_APP_WC_SECRET,
          },
        }
      );

      const totalsByState = totalsResponse.data;

      // 3. Filtrar estados permitidos y calcular totales
      const filteredTotals = totalsByState
        .filter((estado) => estadosPermitidos.includes(estado.slug))
        .map((estado) => {
          const totalOrdersForState = orders.filter(
            (order) => order.status === estado.slug
          ).length;

          return {
            ...estado,
            total: totalOrdersForState,
          };
        });

      // 4. Sumar los totales de los estados permitidos
      const totalOrders = filteredTotals.reduce(
        (acc, estado) => acc + estado.total,
        0
      );

      setTotales({
        totalOrders,
        breakdown: filteredTotals,
      });

      if (totalOrders === 0) {
        setError('No se encontraron datos para las fechas seleccionadas.');
      }
    } catch (error) {
      setError('Error al obtener los datos. Verifica tu conexión o las credenciales.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <p className="subtitle">
        Consulta los totales de órdenes seleccionando un rango de fechas.
      </p>

      {/* Formulario de selección de fechas */}
      <form onSubmit={handleSubmit}>
        <label>
          Selecciona un rango de fechas:
          <br /><br />
          <RangePicker
            onChange={(dates) => setDates(dates)}
            format="YYYY-MM-DD"
            required
          />
        </label>
        <br /><br />
        <Button type="primary" htmlType="submit" style={{ marginTop: '10px' }}>
          Consultar Totales
        </Button>
      </form>

      {/* Mensajes de carga, error o resultados */}
      {loading ? (
        <Spin tip="Cargando..." />
      ) : error ? (
        <Alert message={error} type="error" />
      ) : totales ? (
        <div>
          <h3 className="titleResult">Resultados:</h3>
          <p>Total de Órdenes: {totales.totalOrders}</p>

          {/*
          <h3>Desglose por Estado:</h3>
          <ul className="listResult">
            {totales.breakdown
              .filter((estado) => estado.total > 0)
              .map((estado) => (
                <li key={estado.slug}>
                  {estado.name}: {estado.total}
                </li>
              ))}
          </ul> */}
        </div>
      ) : null}
    </div>
  );
};

export default OrdersTotals;